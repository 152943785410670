import axios from 'axios';

const apiUrl = 'https://bondai-travel-compositor-api-prod.azurewebsites.net/api/v1/';

const API = axios.create({
  baseURL: apiUrl,
  responseType: 'json',
});

export const searchExperiences = async (data) =>
  API.post(`search/experiences`, data);
