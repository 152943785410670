import { createAsyncThunk } from '@reduxjs/toolkit';
import * as BlogAPI from '../../api/BlogAPI';

export const fetchArticles = createAsyncThunk(
  'blog/fetchArticles',
  async ({ page, searchTerm }) => {
    try {
      let response;
      if (searchTerm) {
        response = await BlogAPI.searchArticles(searchTerm, page);
      } else {
        response = await BlogAPI.fetchArticles(page);
      }
      return response.data;
    } catch (e) {
      console.log(e); //todo-fahad remove this
    }
  }
);

export const fetchArticle = createAsyncThunk(
  'blog/fetchArticle',
  async (slug) => {
    try {
      const response = await BlogAPI.fetchArticle(slug);
      return response.data;
    } catch (e) {
      console.log(e); //todo-fahad remove this
    }
  }
); 

export const fetchDestination = createAsyncThunk(
  'blog/fetchDestination',
  async (slug) => {
    try {
      const response = await BlogAPI.fetchDestination(slug);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const fetchDestinations = createAsyncThunk(
  'blog/fetchDestinations',
  async () => {
    try {
      const response = await BlogAPI.fetchDestinations();
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const subscribeNewsletter = createAsyncThunk(
  'blog/subscribeNewsletter',
  async (data) => {
    try {
      const response = await BlogAPI.subscribeNewsletter(data);
      return response.data;
    } catch (e) {
      throw e.response.data.error;
    }
  }
);
