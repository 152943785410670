import { createSlice } from '@reduxjs/toolkit';
import * as effects from './travelcEffects';

const { reducer } = createSlice({
  name: 'travelc',
  initialState: {
    experiences: [],
    loading: {
      experiences: 'idle',
    },
  },
  extraReducers: {
    [effects.searchExperiences.pending]: (state) => {
      if (state.loading.experiences === 'idle') {
        state.loading.experiences = 'pending';
      }
    },
    [effects.searchExperiences.fulfilled]: (state, action) => {
      if (state.loading.experiences === 'pending') {
        state.experiences = action.payload.value.items;
        state.loading.experiences = 'idle';
      }
    },
    [effects.searchExperiences.rejected]: (state) => {
      state.loading.experiences = 'failed';
    },
  },
});

export default reducer;
