import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './blogEffects';

const blogAdapter = createEntityAdapter();

const { actions, reducer } = createSlice({
  name: 'blog',
  initialState: blogAdapter.getInitialState({
    entities: {},
    ids: [],
    featuredArticles: [],
    destination: {},
    destinations: {},
    currentArticle: {},
    search: {
      term: '',
      meta: {
        page: 0,
        pageCount: 0,
      },
    },
    loading: {
      articles: 'idle',
      article: 'idle',
      destination: 'idle',
      destinations: 'idle',
      subscribe: 'idle',
    },
  }),
  reducers: {
    setSearchTerm: (state, action) => {
      state.search.term = action.payload;
    },
  },
  extraReducers: {
    [effects.fetchArticles.pending]: (state, action) => {
      if (state.loading.articles === 'idle') {
        state.loading.articles = 'pending';
      }
    },
    [effects.fetchArticles.fulfilled]: (state, action) => {
      if (state.loading.articles === 'pending') {
        if (state.featuredArticles.length === 0) {
          state.featuredArticles = action.payload.data.slice(0, 4);
        }
        state.search.meta.page = action.payload.meta.pagination.page;
        state.search.meta.pageCount = action.payload.meta.pagination.pageCount;
        if(state.search.meta.page === 1) {
          blogAdapter.setAll(state, action.payload.data);
        } else {
          blogAdapter.addMany(state, action.payload.data);
        }
        state.loading.articles = 'idle';
      }
    },
    [effects.fetchArticles.rejected]: (state) => {
      state.loading.articles = 'idle';
    },
    [effects.fetchArticle.pending]: (state, action) => {
      if (state.loading.article === 'idle') {
        state.loading.article = 'pending';
      }
    },
    [effects.fetchArticle.fulfilled]: (state, action) => {
      if (state.loading.article === 'pending') {
        const article = action.payload.data.find((x) => x);
        if (article) {
          blogAdapter.upsertOne(state, article);
          state.currentArticle = article;
          state.loading.article = 'idle';
        } else {
          state.loading.article = 'invalid';
        }
      }
    },
    [effects.fetchArticle.rejected]: (state) => {
      state.loading.article = 'failed';
    },
    [effects.fetchDestination.pending]: (state) => {
      if (state.loading.destination === 'idle') {
        state.loading.destination = 'pending';
      }
    },
    [effects.fetchDestination.fulfilled]: (state, action) => {
      if (state.loading.destination === 'pending') {
        state.destination = action.payload.data.find((x) => x);
        state.loading.destination = 'idle';
      }
    },
    [effects.fetchDestination.rejected]: (state) => {
      state.loading.destination = 'failed';
    },
    [effects.fetchDestinations.pending]: (state) => {
      if (state.loading.destinations === 'idle') {
        state.loading.destinations = 'pending';
      }
    },
    [effects.fetchDestinations.fulfilled]: (state, action) => {
      if (state.loading.destinations === 'pending') {
        state.destinations = action.payload.data;
        state.loading.destinations = 'idle';
      }
    },
    [effects.fetchDestinations.rejected]: (state) => {
      state.loading.destinations = 'failed';
    },
    [effects.subscribeNewsletter.pending]: (state) => {
        state.loading.subscribe = 'pending';
    },
    [effects.subscribeNewsletter.fulfilled]: (state, action) => {
      if (state.loading.subscribe === 'pending') {
        state.loading.subscribe = 'success';
      }
    },
    [effects.subscribeNewsletter.rejected]: (state, action) => {
      if(action.error?.message?.includes("unique")) {
        state.loading.subscribe = 'duplicate';
      } else {
        state.loading.subscribe = 'failed'; 
      }
    },
  },
});

export const { setSearchTerm } = actions;

export default reducer;
