import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function HelmetMetaData(props) {
  let location = useLocation();
  let currentUrl = process.env.PUBLIC_URL + location.pathname;
  let quote = props.quote ?? '';
  let title =
    props.title ?? 'Bondai - Explore the Kingdom. Less hassle, more travel.';
  let image = props.image ?? '';
  let description = props.description ?? '';
  let hashtag = props.hashtag ?? '';
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Bondai" />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}
