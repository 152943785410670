import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BLOG_API,
  responseType: 'json',
});

export const fetchArticles = (page) =>
  API.get('/articles', {
    params: {
      populate: ['categories', 'author', 'slider.images'].join(),
      sort: 'createdAt:desc',
      'pagination[pageSize]': 12,
      'pagination[page]': page,
      locale: 'all',
    },
  });

export const searchArticles = (searchTerm, page) =>
  API.get('/articles', {
    params: {
      populate: ['categories', 'author', 'slider.images'].join(),
      'filters[$or][0][title][$containsi]': searchTerm,
      // 'filters[$or][1][content][$containsi]': searchTerm,
      sort: 'createdAt:desc',
      'pagination[pageSize]': 12,
      'pagination[page]': page,
      locale: 'all',
    },
  });

export const fetchArticle = (slug) =>
  API.get('/articles', {
    params: {
      populate: ['categories', 'author', 'slider.images'].join(),
      'filters[slug][$eq]': slug,
      locale: 'all',
    },
  });

export const fetchDestination = (slug) =>
  API.get('/destinations', {
    params: {
      populate: 'slider.images',
      'filters[slug][$eq]': slug,
      locale: 'all',
    }
  });

export const fetchDestinations = () =>
  API.get('/destinations', {
    params: {
      populate: 'slider.images',
      locale: 'all',
    }
  });

export const subscribeNewsletter = (data) =>
  API.post('/subscribers', {
    data,
  });
  