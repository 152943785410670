import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Col } from 'shards-react';
import { fetchDestinations } from '../features/blog/blogEffects';
import SectionLoader from './SectionLoader';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';

const destinationSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiArrowRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiArrowLeft />
    </div>
  );
}

const SubscribeNewsletter = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(
    (state) => state.blog.loading.destinations === 'pending'
  );

  const destinations = useSelector((state) => state.blog.destinations);

  useEffect(() => {
    dispatch(fetchDestinations());
  }, []);

  return (
    <div style={{ color: 'black' }}>
      {destinations?.length ? (
        <div className="destination-card-container">
          <Slider {...destinationSettings}>
            {destinations.map(({ id, attributes: destination }) => {
              const image =
                destination.slider.images.data[0].attributes.formats.small.url;
              return (
                <div>
                <Col key={id}>
                  <Link to={`/destination/${destination.slug}`}>
                    <div
                      className="destination-card"
                      style={{ backgroundImage: `url(${image})` }}
                    >
                      <h3>{destination.translatedTitle}</h3>
                    </div>
                  </Link>
                </Col></div>
              );
            })}
          </Slider>
        </div>
      ) : (
        <div className="article-loading-container">
          {isFetching ? (
            <div>
              <SectionLoader label="Fetching destinations..." />
            </div>
          ) : (
            <h5>Destinations not found...</h5>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscribeNewsletter;
