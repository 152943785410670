import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setStickyFilters } from '../features/explore/exploreSlice';
import { useScrollPosition } from '../helpers/hooks';
import Navbar from './Navbar';
import { Container, Row, Col, Slider } from 'shards-react';
import { FaFacebookSquare, FaLinkedin, FaInstagramSquare, FaTwitterSquare, FaMedium, FaYoutubeSquare } from 'react-icons/fa';
import classNames from 'classnames';
import logoIconWhite from '../assets/logo-icon-white.svg';
import history from '../utils/history';
import BondaiLogoBlack from '../assets/images/logo.png';
import BondaiLogoWhite from '../assets/images/logo_white.png';
import SubscribeNewsletter from './SubscribeNewsletter';


const DefaultLayout = ({
  children,
  searchRef,
  filtersRef,
  isSearchTriggered,
  searchTrigger,
}) => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [navbarSearch, setNavbarSearch] = useState(false);
  const contentRef = useRef();
  const dispatch = useDispatch();
  const stickyFilters = useSelector((state) => state.explore.stickyFilters);

  useScrollPosition(
    ({ currPos }) => {
      if (searchRef && searchRef.current.getBoundingClientRect().top < 70) {
        setNavbarSearch(true);
      } else {
        setNavbarSearch(false);
      }
      if (filtersRef?.current &&
        filtersRef.current.getBoundingClientRect().top < 67 - filtersRef.current.getBoundingClientRect().height) {
        if (!stickyFilters) {
          dispatch(setStickyFilters(true));
        }
      } else {
        if (stickyFilters) { dispatch(setStickyFilters(false)); }
      }
      if (currPos.y < -50) {
        setStickyNavbar(true);
      } else {
        setStickyNavbar(false);
      }
    },
    [stickyFilters],
    contentRef,
  );

  return (
    <div className="contentWrapper" ref={contentRef}>
      <div
        className={classNames({
          navbar: true,
          'navbar--isSticky': stickyNavbar,
        })}
      >
        <div className="navbar__header">
          <div className="navbar__logo" onClick={() => history.push('/')}>
            <img
              alt="Bondai"
              src={
                !stickyNavbar
                  ? BondaiLogoWhite
                  : BondaiLogoBlack
              }
              style={{ height: 56, width: 'auto' }}
            />
          </div>
        </div>
      </div>
      {children}


      <Container fluid className="footer">
        <Container style={{ padding: 0 }}>

          <SubscribeNewsletter/>

          <Row className="links">
            <Col xs={12} md={3}>
              <a href="https://bondai.io/about">About Bondai</a>
              <a href="https://bondai.io/onboarding">Become a provider</a>
              <a href="https://bondai.io/inquiry">Create your adventure</a>
              {/* <a href="/contact">Contact us</a> */}
            </Col>
            <Col xs={12} md={3}>
              <a href="https://bondai.io/faqs">FAQs</a>
              <a href="https://app.termly.io/document/terms-of-use-for-website/2369c615-d97e-416c-88b9-a264c76c9a07">Terms & conditions</a>
              <a href="https://app.termly.io/document/privacy-policy/4001754c-91dc-4787-a978-0bd83a98f53f">Privacy policy</a>
            </Col>
            <Col xs={12} md={6}>
              <Row className="suggestion">
                <Col>
                <span>Have any experiences ideas you don't see here?</span>
                <a href="mailto:hi@bondai.io">Send us your suggestions</a> and we'll do our best to make it happen.
                </Col>
              </Row>
              <Row>
                <Col className="socialIcons">
                  <a href="https://www.facebook.com/Bondai.io/?ref=py_c">
                    <FaFacebookSquare />
                  </a>
                  <a href="https://www.linkedin.com/company/bondaiapp">
                    <FaLinkedin />
                  </a>
                  <a href="https://www.instagram.com/bondai.io">
                    <FaInstagramSquare />
                  </a>
                  <a href="https://twitter.com/bondai_io">
                    <FaTwitterSquare />
                  </a>
                  <a href="https://medium.com/@Bondai">
                    <FaMedium />
                  </a>
                  <a href="https://www.youtube.com/channel/UC8vJ7_M1wQFWBPhi14NVU_g">
                    <FaYoutubeSquare />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row align="middle" justify="space-between">
            <Col className="copyright">
              <img src={logoIconWhite} alt="bondai" /> © 2022 Bondai, Inc. All rights reserved.
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.element,
  searchRef: PropTypes.func,
  filtersRef: PropTypes.func,
  isSearchTriggered: PropTypes.bool,
  searchTrigger: PropTypes.func,
};

DefaultLayout.defaultProps = {
  children: null,
  searchRef: null,
  filtersRef: null,
  isSearchTriggered: false,
  searchTrigger: () => { },
};

export default DefaultLayout;
