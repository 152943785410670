import React, { useEffect } from 'react';
import { Route, Switch, Router, useLocation } from 'react-router-dom';
import { Alert, Modal, ModalBody, Button } from 'shards-react';
import { useSelector, useDispatch } from 'react-redux';
import GA4 from 'react-ga4';
import Zendesk from 'react-zendesk';
import DefaultLayout from './components/DefaultLayout';

import history from './utils/history';
import {
  fetchDetails,
  fetchDestinations,
} from './features/auth/userEffects';

import { fetchBookings } from './features/bookings/bookingsEffect';

import { hideModal } from './features/notifications/notificationsSlice';
import { updateCheckout } from './features/bookings/bookingsSlice';

import UpcomingPage from './features/upcoming/UpcomingPage';
import UpcomingByDatePage from './features/upcoming/UpcomingByDatePage';
import AlUlaPage from './features/explore/AlUlaPage';
import MusePage from './features/explore/MusePage';
import ExplorePage from './features/explore/ExplorePage';
import BucketListPage from './features/explore/BucketListPage';
import ActivityPage from './features/explore/ActivityPage';
import LandingPage from './features/explore/LandingPage';
import OnboardingPage from './features/auth/OnboardingPage';
import CheckoutPage from './features/bookings/CheckoutPage';
import PaymentGatewayErrorPage from './features/bookings/PaymentGatewayErrorPage';
import PaymentGatewayPage from './features/bookings/PaymentGatewayPage';
import PaymentStatusPage from './features/bookings/PaymentStatusPage';
import BookingsPage from './features/bookings/BookingsPage';
import ReviewPage from './features/bookings/ReviewPage';
import ForgotPasswordPage from './features/auth/ForgotPasswordPage';
import ResetPasswordPage from './features/auth/ResetPasswordPage';
import SignInPage from './features/auth/SignInPage';
import RegisterPage from './features/auth/RegisterPage';
import InquiryPage from './features/auth/InquiryPage';
import AboutPage from './features/landing/AboutPage';
import FaqsPage from './features/landing/FaqsPage';
import DashboardLayout from './features/dashboard/DashboardLayout';
import Blog from './features/blog/BlogDashboard';
import ArticlePage from './features/blog/ArticlePage';
import DestinationPage from './features/blog/DestinationPage';

const hitType = "pageview";
const sendGAPageViewEvent = (location) => {
  GA4.set({ page: location.pathname });
  GA4.send({ hitType, page: location.pathname });
}
if (process.env.NODE_ENV === 'production') {
  sendGAPageViewEvent(history.location);
  history.listen((location) => {
    sendGAPageViewEvent(location);
  });
}
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const checkout = localStorage.getItem('checkout');
    if (checkout) {
      dispatch(updateCheckout(JSON.parse(checkout)));
    }
    if (localStorage.getItem('token')) {
      dispatch(fetchDestinations());
      // dispatch(fetchCurrencies());
      dispatch(fetchDetails());
      if (localStorage.getItem('role') === 'user') dispatch(fetchBookings());
    }

    window.addEventListener('storage', (storage) => {
      if (storage.key === 'token') {
        window.location.reload();
      }
    });
  }, [dispatch]);

  const notifications = useSelector((state) =>
    state.notifications.ids.map((id) => state.notifications.entities[id]),
  );

  return (
    <>
      <div className='notificationsContainer'>
        {notifications.map(({ type, message, id, dismissLabel }) =>
          type === 'modal' ? (
            <Modal
              size='md'
              open={true}
              toggle={() => dispatch(hideModal(id))}
              centered
              animation={false}
              fade={false}
              className='bondai-custom'
            >
              <ModalBody style={{ color: 'black' }}>
                {message}
                <Button
                  onClick={() => dispatch(hideModal(id))}
                  style={{
                    display: 'block',
                    float: 'right',
                    marginTop: 40,
                  }}
                  className='bucketListActivity'
                >
                  {dismissLabel}
                </Button>
              </ModalBody>
            </Modal>
          ) : (
              <Alert
                open={true}
                theme={type}
                style={{
                  width: '100%',
                  marginBottom: 0,
                }}
              >
                {message}
              </Alert>
            ),
        )}
      </div>
      <Zendesk
        defer
        zendeskKey={process.env.REACT_APP_ZENDESK}
      />
      <DefaultLayout>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            {/* <Route exact path='/onboarding' component={OnboardingPage} />
            <Route
              exact
              path='/reset-password/:token'
              component={ResetPasswordPage}
            />
            <Route path='/about' component={AboutPage} />
            <Route path='/faqs' component={FaqsPage} />
            <Route exact path='/forgot-password' component={ForgotPasswordPage} />
            <Route exact path='/muse-exclusives' component={MusePage} />
            <Route exact path='/explore-al-ula' component={AlUlaPage} />
            <Route exact path='/calendar' component={UpcomingPage} />
            <Route exact path='/calendar/:date' component={UpcomingByDatePage} />
            <Route exact path='/explore' component={ExplorePage} />
            <Route exact path='/checkout' component={CheckoutPage} />
            <Route exact path='/bucket-list' component={BucketListPage} />
            <Route exact path='/inquiry' component={InquiryPage} />
            <Route exact path='/bookings' component={BookingsPage} /> */}
            <Route exact path='/articles' component={Blog}/>
            <Route exact path='/article/:slug' component={ArticlePage}/>
            <Route exact path='/destination/:slug' component={DestinationPage}/>
            {/* <Route path='/payment-gateway/error' component={PaymentGatewayErrorPage} />
            <Route path='/payment-gateway/:token' component={PaymentGatewayPage} />
            <Route path='/payment-status/:id' component={PaymentStatusPage} />
            <Route path='/review/:token' component={ReviewPage} />
            <Route path='/explore/:id' component={ActivityPage} />
            <Route path='/experience/:slug' component={ActivityPage} />
            <Route path='/private/:id' component={ActivityPage} />
            <Route path='/login' component={SignInPage} />
            <Route path='/register' component={RegisterPage} />
            <Route path='/dashboard' component={DashboardLayout} /> */}
            <Route path='/' component={Blog} />
          </Switch>
        </Router>
      </DefaultLayout>
    </>
  );
};

export default App;
