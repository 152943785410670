import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from '../../api/TravelcAPI';

export const searchExperiences = createAsyncThunk(
  'travelc/searchExperiences',
  async ({ slug, lang }) => {
    const response = await API.searchExperiences({
      take: 3,
      from: 0,
      searchTerm: '',
      sortOrder: 0,
      languageCode: lang,
      destinationSlugs: [slug],
      contentType: 10,
    });
    return response.data;
  }
);
