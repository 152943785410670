import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button, FormInput, InputGroup } from 'shards-react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchArticles } from './blogEffects';
import SectionLoader from '../../components/SectionLoader';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { MdSearch } from 'react-icons/md';
import { setSearchTerm } from './blogSlice';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { Remark } from 'react-remark';
import strip from 'strip-markdown';
import TopDestinations from '../../components/TopDestinations';

const sliderSettings = {
  dots: true,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false,
};

const BlogDashboard = () => {
  const dispatch = useDispatch();
  const footerRef = useRef(null);

  const searchTerm = useSelector((state) => state.blog.search.term);
  const searchMeta = useSelector((state) => state.blog.search.meta);

  const [searchInputValue, setSearchInputValue] = useState('');

  const articles = useSelector((state) =>
    state.blog.ids.map((id) => ({
      ...state.blog.entities[id],
    }))
  );

  const featuredArticles = useSelector((state) => state.blog.featuredArticles);

  const isFetching = useSelector(
    (state) => state.blog.loading.articles === 'pending'
  );

  useEffect(() => {
    dispatch(fetchArticles({ page: 1, searchTerm }));
  }, [searchTerm]);

  const scrollHandler = () => {
    if (!footerRef.current) return;
    const footerRect = footerRef.current.getBoundingClientRect();
    if (
      footerRect.top >= 0 &&
      !isFetching &&
      searchMeta.page < searchMeta.pageCount &&
      Math.floor(footerRect.bottom) <=
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      dispatch(fetchArticles({ page: searchMeta.page + 1, searchTerm }));
    }
  };

  useEffect(() => {
    if (!isFetching) {
      window.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [isFetching]);

  return (
    <div className="blog-dashboard">
      <div className='slider-container'>
        <Slider {...sliderSettings}>
          {featuredArticles?.length
            ? featuredArticles.map(({ id, attributes: article }) => {
              let image;
              if (article.slider) {
                image = article.slider.images.data[0].attributes;
                image = image.formats.large ?? image;
              }
              const isArabic = article.locale === "ar";
              return (
                <div key={id}>
                  <div
                    className="slider-content-container"
                    style={{ backgroundImage: `url(${image.url})` }}
                  >
                    <Link
                      to={`/article/${article.slug}`}
                      className="text-container-image"
                    >
                      <Container style={{ textAlign: 'center' }}>
                        <h1
                          className={
                            'slider-heading' + (isArabic ? ' arabic-heading' : '')
                          }
                        >
                          {article.title}
                        </h1>
                        <div className="read-more-link">Read More {'>'}</div>
                      </Container>
                    </Link>
                  </div>
                </div>
              );
            })
            : null}
        </Slider>
      </div>
      <Container>
        <h2 className='text-center text-dark margin-top'>Top Destinations</h2>
        <TopDestinations/>
        <Row>
          <Col className="heading-container">
            {/* <h1 className="heading">Stories</h1> */}
            <div className="search-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(setSearchTerm(searchInputValue));
                }}
                className="search"
              >
                <InputGroup size="sm" seamless>
                  <Button type="prepend">
                    <MdSearch size={20} style={{ marginRight: 5 }} />
                    Search
                  </Button>
                  <FormInput
                    placeholder="Search Stories..."
                    value={searchInputValue}
                    onChange={(e) => setSearchInputValue(e.target.value)}
                  />
                </InputGroup>
              </form>
            </div>
          </Col>
        </Row>
        {articles?.length ? (
          <Row className="articles-card-container">
            {articles.map(({ id, attributes: article }) => {
              const author = article.author.data?.attributes;
              const isArabic = article.locale === "ar";
              let image;
              if (article.slider) {
                image = article.slider.images.data[0].attributes;
                image = image.formats.small ?? image;
              }
              return (
                <Col
                  xs={'auto'}
                  sm={6}
                  lg={4}
                  style={{ marginBottom: '40px' }}
                  key={id}
                  className={
                    'blog-col-container' + (isArabic ? ' arabic-card' : '')
                  }
                >
                  <Link className='blog-detail-container'
                    to={`/article/${article.slug}`}>
                    <div className="content-container">
                      <img
                        className="blog-image"
                        src={image.url}
                        alt={image.alt}
                      />
                      <div className="article-text-container">
                        <div className="blog-text">
                          By<span className="auther-name">{author?.name ?? "Bondai"}</span>
                        </div>
                        <div className="article-heading">
                          {article.title}
                        </div>
                        <div className="article-content">
                          <Remark remarkPlugins={[strip]}>{article.content.substr(0, 350)}</Remark>
                        </div>
                        <div className="articles-details">
                          <div className="blog-date">{moment(article.publishedAt).format('DD MMMM YYYY')}</div>
                          <div className="blog-dot" />
                          <div className="blog-date">{Math.ceil(article.content.split(' ').length / 150)} min read</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        ) : isFetching ? (
          <SectionLoader label="Fetching articles..." />
        ) : (
          <h5>No articles found...</h5>
        )}
        <div ref={footerRef} style={{ minHeight: 60 }}>
          {searchMeta.page === searchMeta.pageCount ? null : (
            <Loader
              type="Rings"
              color="#000000"
              height={80}
              width={80}
              style={{ textAlign: 'center' }}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default BlogDashboard;
