import { createAsyncThunk } from '@reduxjs/toolkit';
import ActivitiesAPI from '../../api/ActivitiesAPI';

const perPage = 12; //TODO move this in store - allow dynamic adjustment based on screen height

export const search = createAsyncThunk(
  'explore/searchStatus',
  async ({ searchTerm = '', page = 0, filters = {}, scheduleFiltering = 1 }) => {
    const notFiltered = !searchTerm && !filters.location.value && !filters.tagIds.length;
    const response = await ActivitiesAPI.search({
      searchTerm,
      tagIds: filters.tagIds,
      ...filters.location.value && {
        googlePlaceId: filters.location.value,
        sortOrder: 6
      },
      priceFilter: (filters.minPrice !== 0 || filters.maxPrice !== 10000) ? {
        ticketTypeId: process.env.REACT_APP_ADULT_TICKET_ID,
        minPrice: filters.minPrice,
        maxPrice: filters.maxPrice,
      } : {},
      latitude: null,
      longitude: null,
      take: perPage + (notFiltered && !page ? 1 : 0),
      from: page * perPage + (notFiltered && page ? 1 : 0),
      excludeTourProviderId: process.env.REACT_APP_MUSE_ID,
      excludeTagIds: [process.env.REACT_APP_AL_ULA_PRIVATE],
      scheduleFiltering,
      distanceInKilometers: 100,
    });
    return response.data.value;
  },
);

export const fetchPopularExperiences = createAsyncThunk(
  'explore/fetchPopularExperiences',
  async () => {
    const response = await ActivitiesAPI.search({
      sortOrder: 8,
      latitude: null,
      longitude: null,
      priceFilter: {},
      searchTerm: "",
      tagIds: [],
      take: 4,
      from: 0,
      excludeTourProviderId: process.env.REACT_APP_MUSE_ID,
      excludeTagIds: [process.env.REACT_APP_AL_ULA_PRIVATE],
    });
    return response.data.value;
  },
);

export const fetchUpcomingExperiences = createAsyncThunk(
  'explore/fetchUpcomingExperiences',
  async () => {
    const response = await ActivitiesAPI.search({
      sortOrder: 9,
      latitude: null,
      longitude: null,
      priceFilter: {},
      searchTerm: "",
      tagIds: [],
      take: 8,
      from: 0,
      excludeTourProviderId: process.env.REACT_APP_MUSE_ID,
      excludeTagIds: [process.env.REACT_APP_AL_ULA_PRIVATE],
    });
    return response.data.value;
  },
);


export const fetchExperiencesByTagIds = createAsyncThunk(
  'explore/fetchExperiencesByTagIdsStatus',
  async (tagIds) => {
    const response = await ActivitiesAPI.search({
      take: 999,
      from: 0,
      tagIds,
    });
    return response.data.value;
  },
)

export const fetchExperiencesByProviderId = createAsyncThunk(
  'explore/fetchExperiencesStatus',
  async (tourProviderId) => {
    const response = await ActivitiesAPI.search({
      tourProviderId,
      includePrivate: true,
      take: 999,
      from: 0,
    });
    return response.data.value;
  },
)

export const fetchActivity = createAsyncThunk(
  'explore/fetchActivityStatus',
  async ({ id, isSlug }) => {
    const activityDetails = isSlug
      ? await ActivitiesAPI.fetchActivityBySlug(id)
      : await ActivitiesAPI.fetchActivityByShortId(id);
    const reviewsData = await ActivitiesAPI.fetchReviewsByActivityId(activityDetails.data.value.id);
    return {
      ...activityDetails.data.value,
      reviews: reviewsData.data.value.items
    };
  },
);
/*
"sortOrder": 0,
"lastActivityTimeStamp": "2020-09-22T09:33:56.313Z",
"includePrivate": true,
"tagIds": [
  "string"
],
"tourProviderId": "string",
"priceFilter": {
  "ticketTypeId": "string",
  "minPrice": 0,
  "maxPrice": 0
},
"googlePlaceId": "string",
"latitude": 0,
"longitude": 0,
"searchTerm": "string",
"take": 0,
"from": 0 */