//https://github.com/remark-embedder/transformer-oembed

const oembedCached = [{
  "provider_name": "YouTube",
  "provider_url": "https://www.youtube.com/",
  "endpoints": [
    {
      "schemes": [
        "https://*.youtube.com/watch*",
        "https://*.youtube.com/v/*",
        "https://youtu.be/*",
        "https://*.youtube.com/playlist?list=*",
        "https://youtube.com/playlist?list=*"
      ],
      "url": "https://www.youtube.com/oembed",
      "discovery": true
    }
  ]
}];

async function getProviders() {
  try {
    return oembedCached;
  } catch (e) {
    console.log(e);
  }
}

// TODO: Support providers that do not have schemes
async function getProviderEndpointURLForURL(url) {
  const providers = await getProviders();
  for (const provider of providers) {
    for (const endpoint of provider.endpoints) {
      if (
        endpoint.schemes?.some((scheme) =>
          new RegExp(scheme.replace(/\*/g, '(.*)')).test(url)
        )
      ) {
        return { provider, endpoint: endpoint.url };
      }
    }
  }
  return null;
}

const transformer = {
  name: '@remark-embedder/transformer-oembed',
  shouldTransform: async (url) => {

    const result = await getProviderEndpointURLForURL(url);
    return Boolean(result);
  },
  getHTML: async (urlString) => {
    const result = await getProviderEndpointURLForURL(urlString);

    // istanbul ignore if (shouldTransform prevents this, but if someone calls this directly then this would save them)
    if (!result) return null;

    const { endpoint } = result;

    const url = new URL(endpoint);
    url.searchParams.set('url', urlString);

    // format has to be json so it is not configurable
    url.searchParams.set('format', 'json');

    const res = await fetch(url.toString());
    const data = await res.json();

    return data.html;
  },
};

export default transformer;
