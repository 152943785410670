import React from 'react';
import { render } from 'react-dom';
import GA4 from 'react-ga4';
import * as Sentry from '@sentry/react';
import { hotjar } from 'react-hotjar';

import '../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/shards-ui/dist/css/shards.min.css';
import 'rsuite/dist/styles/rsuite-default.css';
import './index.scss';

import { Provider } from 'react-redux';
import configureAppStore from './store';

import App from './App';
import Header from './Header';

if (['production', 'staging'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    dsn:
      'https://24530bd346e14a18afb6e97f14171706@o411956.ingest.sentry.io/5287917',
    beforeSend(event, hint) {
      if (
        event &&
        event.title &&
        event.title.match(/languageParser/)
      ) return null;
      const error = hint.originalException;
      if (
        error &&
        error.message &&
        error.message.match(/languageParser/i)
      ) {
        return null;
      }
      return event;
    },
  });
}

if (process.env.NODE_ENV === 'production') {
  GA4.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_ID,
    process.env.REACT_APP_HOTJAR_SV,
  );
}

const ErrorPage = (props) => (
  <div>
    <Header />
    <h1
      style={{
        display: 'inline',
        lineHeight: 1,
        verticalAlign: 'bottom',
        fontWeight: 900,
        fontSize: 48,
        letterSpacing: 1.25,
        color: '#000',
        paddingTop: 30,
        marginLeft: 30,
      }}
    >
      Oups! Something went wrong...
    </h1>
  </div>
);

export const store = configureAppStore({});

render(
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
      <App />
    </Sentry.ErrorBoundary>
  </Provider>,
  document.querySelector('#root'),
);
