import axios from 'axios';
import * as Sentry from '@sentry/react';
import history from './history';
import { store } from '../index';
import { logout } from '../features/auth/userSlice';
import { notify } from '../features/notifications/notificationsEffects';

const allowedUnauthorizedCalls = ['accounts/login'];

export const authenticate = (token) =>
  token || localStorage.getItem('token')
    ? {
      headers: { Authorization: `Bearer ${token || localStorage.getItem('token')}` },
    }
    : {};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) return;
    Sentry.setExtra('Request data', JSON.stringify(error.response.config.data));
    Sentry.setExtra(
      'Request headers',
      JSON.stringify(error.response.config.headers),
    );
    Sentry.setExtra(
      'Request method',
      error.response.config.method + '/' + error.response.config.responseType,
    );
    Sentry.setExtra('Request url', error.response.config.url);
    Sentry.setExtra('Response data', JSON.stringify(error.response.data));
    Sentry.setExtra(
      'Response value',
      error.response.data ? JSON.stringify(error.response.data.value) : '',
    );
    Sentry.setExtra('Response', JSON.stringify(error.response));
    Sentry.captureException(error);

    if (
      error.response.status === 401 &&
      !allowedUnauthorizedCalls.includes(
        error.response.config.url.split(error.response.config.baseURL)[1],
      ) &&
      store.getState().user.loading.logout !== 'pending'
    ) {
      store.dispatch(
        notify({
          message: `Oups, your session has expired! Please login again.`,
          type: 'warning',
        }),
      );
      store.dispatch(logout('login'));
      history.push('/login');
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
