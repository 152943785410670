import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'shards-react';
import { fetchDestination } from './blogEffects';
import { Remark } from 'react-remark';
import SectionLoader from '../../components/SectionLoader';
import { Link } from 'react-router-dom';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import HelmetMetaData from '../../components/HelmetMetaData';
import history from '../../utils/history';
import remarkEmbedder from '@remark-embedder/core';
import oembedTransformer from './oembedTransformer';
import Slider from 'react-slick';
import { searchExperiences } from '../travelc/travelcEffects';

const sliderSettings = {
  dots: true,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false,
};

const DestinationPage = (props) => {
  const dispatch = useDispatch();
  const slug = props.match.params.slug;
  const currentUrl = process.env.PUBLIC_URL + history.location.pathname;
  const shareIconSettings = { size: 32, borderRadius: 10 };
  const shareSettings = { url: currentUrl };

  const isFetching = useSelector(
    (state) => state.blog.loading.destination === 'pending'
  );

  const destination = useSelector(
    (state) => state.blog.destination
  )?.attributes;
  const experiences = useSelector((state) => state.travelc.experiences);

  useEffect(() => {
    dispatch(fetchDestination(slug));
  }, []);

  useEffect(() => {
    if (destination) {
      dispatch(
        searchExperiences({
          slug: slug.toLowerCase(),
          lang: isArabic ? 'ar' : 'en',
        })
      );
    }
  }, [destination]);

  const slider = destination?.slider;
  let image;
  if (slider) {
    image = slider.images.data[0].attributes;
    image = image.formats.large ?? image;
  }

  const isArabic = destination?.locale === 'ar';
  const rtlLayout = isArabic ? 'rtl-article' : '';

  return (
    <div style={{ color: 'black' }}>
      {destination ? (
        <div>
          {slider.images.data.length === 1 ? (
            <div
              style={{ backgroundImage: `url(${image.url})` }}
              className="header-background-image"
            />
          ) : (
            <div className="slider-container">
              <Slider {...sliderSettings}>
                {slider.images.data.map(({ id, attributes: image }) => {
                  image = image.formats.large ?? image;
                  return (
                    <div key={id}>
                      <div
                        className="slider-content-container"
                        style={{ backgroundImage: `url(${image.url})` }}
                      ></div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
          <Container className={rtlLayout}>
            <div>
              <div>
                <h1 className="blog-article-heading">{destination.name}</h1>
              </div>
              <div className="react-mark-down-container">
                <Remark
                  remarkPlugins={[
                    [
                      remarkEmbedder,
                      {
                        transformers: [oembedTransformer],
                      },
                    ],
                  ]}
                >
                  {destination.description}
                </Remark>
                <hr />
              </div>
            </div>
            <div className={isArabic ? 'arabic-layout' : ''}>
              <p className="share-heading">
                {isArabic ? 'شارك هذه الوجهة:' : 'SHARE THIS DESTINATION:'}
              </p>
              <HelmetMetaData
                title={destination.name}
                image={image.url}
                description={destination.description}
              />
              <div className="social-links-container">
                <FacebookShareButton {...shareSettings}>
                  <FacebookIcon {...shareIconSettings} />
                </FacebookShareButton>
                <TwitterShareButton {...shareSettings}>
                  <TwitterIcon {...shareIconSettings} />
                </TwitterShareButton>
                <WhatsappShareButton
                  {...shareSettings}
                  title={destination.name}
                >
                  <WhatsappIcon {...shareIconSettings} />
                </WhatsappShareButton>
                <LinkedinShareButton {...shareSettings}>
                  <LinkedinIcon {...shareIconSettings} />
                </LinkedinShareButton>
                <PinterestShareButton {...shareSettings} media={image.url}>
                  <PinterestIcon {...shareIconSettings} />
                </PinterestShareButton>
                <RedditShareButton {...shareSettings} title={destination.name}>
                  <RedditIcon {...shareIconSettings} />
                </RedditShareButton>
              </div>
              <h3>
                {isArabic
                  ? `استكشف ${destination.name}`
                  : `Experience ${destination.name} with us`}
              </h3>
              <Row>
                {experiences
                  ? experiences.map((e) => (
                      <Col sm={12} md={6} lg={4}>
                        <div key={e.id}>
                          <a href={e.ideaUrl} className="link-no-style">
                            <div
                              className="experience-card"
                              style={{ backgroundImage: `url(${e.imageUrl})` }}
                            >
                              <p className="experience-title">
                                {e.translatedTitle}
                              </p>
                              <div className="experience-price-container">
                                <p>{isArabic ? 'ابتداء من' : 'From'}</p>
                                <p className="experience-price">{`${Math.round(
                                  e.pricePerPerson.amount
                                )} ${e.pricePerPerson.currency}`}</p>
                                <p>
                                  {isArabic ? 'للشخص الواحد' : 'Per person'}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Col>
                    ))
                  : null}
              </Row>
            </div>
          </Container>
        </div>
      ) : (
        <div className="article-loading-container">
          {isFetching ? (
            <div>
              <SectionLoader label="Fetching articles..." />
            </div>
          ) : (
            <h5>Page not found...</h5>
          )}
        </div>
      )}
    </div>
  );
};

export default DestinationPage;
