import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import { fetchArticle, fetchArticles } from './blogEffects';
import { Remark } from 'react-remark';
import moment from 'moment';
import SectionLoader from '../../components/SectionLoader';
import { Link } from 'react-router-dom';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import HelmetMetaData from '../../components/HelmetMetaData'
import history from '../../utils/history';
import remarkEmbedder from '@remark-embedder/core';
import oembedTransformer from './oembedTransformer';
import strip from 'strip-markdown';
import Slider from 'react-slick';

const sliderSettings = {
  dots: true,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false,
};

const ArticlePage = (props) => {
  const dispatch = useDispatch();
  const slug = props.match.params.slug;
  const currentUrl = process.env.PUBLIC_URL + history.location.pathname;
  const shareIconSettings = { size: 32, borderRadius: 10 };
  const shareSettings = { url: currentUrl};

  const isFetching = useSelector(
    (state) => state.blog.loading.article === 'pending'
  );

  const articleObj = useSelector(
    (state) =>
      state.blog.entities[
      state.blog.ids.find(
        (id) => state.blog.entities[id].attributes.slug === slug
      )
      ]
  );
  
  const currentArticle = useSelector((state) => state.blog.currentArticle);

  const articles = useSelector((state) =>
    state.blog.ids.map((id) => ({
      ...state.blog.entities[id],
    }))
  );

  useEffect(() => {
    dispatch(fetchArticle(slug));
    if (!articles || (articles.length <= 1)) {
      dispatch(fetchArticles({ page: 1 }));
    }
  }, []);

  const article = articleObj?.attributes ?? currentArticle?.attributes;
  const author = article?.author.data?.attributes;
  const rtlLayout = article?.locale === "ar" ? "rtl-article": "";
  const slider = article?.slider;
  let image;
  if(slider) {
    image = slider.images.data[0].attributes;
    image = image.formats.large ?? image;
  }
  
  return (
    <div style={{ color: 'black' }}>
      {article ? (
        <div>
          {slider.images.data.length === 1 ? (
            <div
              style={{ backgroundImage: `url(${image.url})` }}
              className="header-background-image"
            />
          ) : (
            <div className="slider-container">
              <Slider {...sliderSettings}>
                {slider.images.data.map(({ id, attributes: image }) => {
                  image = image.formats.large ?? image;
                  return (
                    <div key={id}>
                      <div
                        className="slider-content-container"
                        style={{ backgroundImage: `url(${image.url})` }}
                      ></div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
          <Container>
            <div className={rtlLayout}>
              <div>
                <h1 className="blog-article-heading">{article.title}</h1>
                <p>{author?.name ?? 'Bondai Writer'}</p>
                <p>{moment(article.publishedAt).format('DD/MM/YYYY h:mm A')}</p>
              </div>
              <div className="react-mark-down-container">
                <Remark
                  remarkPlugins={[
                    [
                      remarkEmbedder,
                      {
                        transformers: [oembedTransformer],
                      },
                    ],
                  ]}
                >
                  {article.content}
                </Remark>
                <hr />
              </div>
            </div>
            <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 10 }}>
              SHARE THIS STORY:
            </p>
            <HelmetMetaData
              title={article.title}
              image={image.url}
              description={article.description}
            />
            <div className="social-links-container">
              <FacebookShareButton {...shareSettings}>
                <FacebookIcon {...shareIconSettings} />
              </FacebookShareButton>
              <TwitterShareButton {...shareSettings}>
                <TwitterIcon {...shareIconSettings} />
              </TwitterShareButton>
              <WhatsappShareButton {...shareSettings} title={article.title}>
                <WhatsappIcon {...shareIconSettings} />
              </WhatsappShareButton>
              <LinkedinShareButton {...shareSettings}>
                <LinkedinIcon {...shareIconSettings} />
              </LinkedinShareButton>
              <PinterestShareButton {...shareSettings} media={image.url}>
                <PinterestIcon {...shareIconSettings} />
              </PinterestShareButton>
              <RedditShareButton {...shareSettings} title={article.title}>
                <RedditIcon {...shareIconSettings} />
              </RedditShareButton>
            </div>
            <h1 className="related-article-heading">Related Articles</h1>
            <Row className="articles-card-container">
              {articles.slice(0, 3).map(({ id, attributes: article }) => {
                const author = article.author.data?.attributes;
                const isArabic = article.locale === 'ar';
                let image;
                if (article.slider) {
                  image = article.slider.images.data[0].attributes;
                  image = image.formats.small ?? image;
                }
                return (
                  <Col
                    xs={'auto'}
                    sm={6}
                    lg={4}
                    style={{ marginBottom: '40px' }}
                    key={id}
                    className={
                      'blog-col-container' + (isArabic ? ' arabic-card' : '')
                    }
                  >
                    <Link
                      className="blog-detail-container"
                      to={`/article/${article.slug}`}
                    >
                      <div className="content-container">
                        <img
                          className="blog-image"
                          src={image.url}
                          alt={image.alt}
                        />
                        <div className="article-text-container">
                          <div className="blog-text">
                            By
                            <span className="auther-name">
                              {author?.name ?? 'Bondai'}
                            </span>
                          </div>
                          <div className="article-heading">{article.title}</div>
                          <div className="article-content">
                            <Remark remarkPlugins={[strip]}>
                              {article.content.substr(0, 350)}
                            </Remark>
                          </div>
                          <div className="articles-details">
                            <div className="blog-date">
                              {moment(article.publishedAt).format(
                                'DD MMMM YYYY'
                              )}
                            </div>
                            <div className="blog-dot" />
                            <div className="blog-date">
                              {Math.ceil(
                                article.content.split(' ').length / 150
                              )}{' '}
                              min read
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      ) : (
        <div className="article-loading-container">
          {isFetching ? (
            <div>
              <SectionLoader label="Fetching articles..." />
            </div>
          ) : (
            <h5>Page not found...</h5>
          )}
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
