import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeNewsletter } from '../features/blog/blogEffects';

const SubscribeNewsletter = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState(0);
  const languages = ['English', 'Arabic', 'Both'];
  const status = useSelector((state) => state.blog.loading.subscribe);
  const subscriptionSent = status === 'success';
  const subscribing = status === 'pending';
  const duplicate = status === 'duplicate';
  let title = 'Subscribe for Newsletter';
  if (subscriptionSent) {
    title = 'Please check your email to complete verification.';
  } else if (duplicate) {
    title = 'Email already subscribed';
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(subscribeNewsletter({ email, language: languages[language] }));
  };

  return (
    <div className="container text-dark mb-5 mt-2">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className='lg-newsletter'>
            <h4 className="text-white">{title}</h4>
            <form
              onSubmit={handleSubmit}
              className={
                'rounded-2' + (subscriptionSent ? 'invisible' : '')
              }
            >
              <div className="d-flex">
                <select
                  style={{ width: '30%' }}
                  className="bg-white rounded-0 rounded-left"
                  name="language"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={language}
                >
                  {languages.map((lan, index) => (
                    <option key={index} value={index}>
                      {lan}
                    </option>
                  ))}
                </select>
                <input
                  required="required"
                  id="email_news"
                  className="form-control rounded-0 mb-0"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></input>
                <button
                  className="btn btn-primary rounded-0 m-0 px-3 rounded-right"
                  type="submit"
                  disabled={subscribing}
                >
                  {subscribing ? 'Subscribing' : 'Subscribe'}
                </button>
              </div>
            </form>
          </div>
          <div className="row md-newsletter">
            <h4 className="text-white" style={{fontSize: 20}}>{title}</h4>
            <form
              onSubmit={handleSubmit}
              className={' ' + (subscriptionSent ? 'invisible' : '')}
            >
              <div className="d-flex align-items-baseline mb-2">
                <label className='text-white' style={{fontSize: 15, marginRight: 10}}>Language</label>
                <select
                  className="form-control bg-white rounded"
                  name="language"
                  onChange={(e) => setLanguage(e.target.value)}
                  value={language}
                >
                  {languages.map((lan, index) => (
                    <option key={index} value={index}>
                      {lan}
                    </option>
                  ))}
                </select>
              </div>
              <input
                required="required"
                id="email_news"
                className="form-control rounded mb-2"
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
              <button
                className="form-control btn btn-primary rounded m-0 px-3 rounded-right"
                type="submit"
                disabled={subscribing}
              >
                {subscribing ? 'Subscribing' : 'Subscribe'}
              </button>
            </form>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
};

export default SubscribeNewsletter;
