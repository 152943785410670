import { FiBriefcase, FiMapPin, FiTag } from 'react-icons/fi';

export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl = 'https://bondai-prod.azurewebsites.net/api';

export const supportedLangs = [
  { value: 'en', label: 'English' },
  { value: 'ar', label: 'Arabic' },
];

export const countryId = '5e667a5d53312627e4d9244f';

export const placesTypes = {
  LOCATION: 'geocode',
  ADDRESS: 'address',
  PLACE: 'establishment',
  REGIONS: '(regions)',
  CITIES: '(cities)',
};

export const languages = {
  ar: { name: "Arabic", flagCountryCode: "SA" },
  az: { name: "Azerbaijani", flagCountryCode: "AZ" },
  be: { name: "Belorussian", flagCountryCode: "BY" },
  bg: { name: "Bulgarian", flagCountryCode: "BG" },
  bn: { name: "Bengali", flagCountryCode: "BD" },
  bs: { name: "Bosnian", flagCountryCode: "BA" },
  // ca: { name: "Catalan", flagCountryCode: "AD" },
  cs: { name: "Czech", flagCountryCode: "CZ" },
  da: { name: "Danish", flagCountryCode: "DK" },
  de: { name: "German", flagCountryCode: "DE" },
  el: { name: "Greek", flagCountryCode: "GR" },
  en: { name: "English", flagCountryCode: "GB" },
  es: { name: "Spanish", flagCountryCode: "ES" },
  et: { name: "Estonian", flagCountryCode: "EE" },
  fa: { name: "Persian", flagCountryCode: "IR" },
  fi: { name: "Finnish", flagCountryCode: "FI" },
  fr: { name: "French", flagCountryCode: "FR" },
  // gl: { name: "Galician", flagCountryCode: "" },
  he: { name: "Hebrew", flagCountryCode: "IL" },
  hi: { name: "Hindi", flagCountryCode: "IN" },
  hr: { name: "Croatian", flagCountryCode: "HR" },
  hu: { name: "Hungarian", flagCountryCode: "HU" },
  hy: { name: "Armenian", flagCountryCode: "AM" },
  id: { name: "Indonesian", flagCountryCode: "ID" },
  it: { name: "Italian", flagCountryCode: "IT" },
  ja: { name: "Japanese", flagCountryCode: "JP" },
  ka: { name: "Georgian", flagCountryCode: "GE" },
  kk: { name: "Kazakh", flagCountryCode: "KZ" },
  ko: { name: "Korean", flagCountryCode: "KR" },
  ky: { name: "Kyrgyz", flagCountryCode: "KG" },
  lt: { name: "Lithuanian", flagCountryCode: "LT" },
  lv: { name: "Latvian", flagCountryCode: "LV" },
  mk: { name: "Macedonian", flagCountryCode: "MK" },
  mn: { name: "Mongolian", flagCountryCode: "MN" },
  ms: { name: "Malay", flagCountryCode: "MY" },
  nb: { name: "Norwegian Bokmål", flagCountryCode: "NO" },
  nl: { name: "Dutch", flagCountryCode: "NL" },
  nn: { name: "Norwegian Nynorsk", flagCountryCode: "NO" },
  pl: { name: "Polish", flagCountryCode: "PL" },
  pt: { name: "Portuguese", flagCountryCode: "PT" },
  ro: { name: "Romanian", flagCountryCode: "RO" },
  ru: { name: "Russian", flagCountryCode: "RU" },
  sk: { name: "Slovak", flagCountryCode: "SK" },
  sl: { name: "Slovene", flagCountryCode: "SI" },
  sr: { name: "Serbian", flagCountryCode: "RS" },
  sv: { name: "Swedish", flagCountryCode: "SE" },
  th: { name: "Thai", flagCountryCode: "TH" },
  tr: { name: "Turkish", flagCountryCode: "TR" },
  uk: { name: "Ukrainian", flagCountryCode: "UA" },
  ur: { name: "Urdu", flagCountryCode: "PK" },
  uz: { name: "Uzbek", flagCountryCode: "UZ" },
  vi: { name: "Vietnamese", flagCountryCode: "VN" },
  zh: { name: "Chinese", flagCountryCode: "CN" },
};

export const paymentStatusLabel = {
  PAID: 'Paid',
  FAILED: 'Payment failed',
  UNPAID: 'Unpaid',
  REFUNDED: 'Refunded',
};

export const paymentStatus = {
  0: paymentStatusLabel.UNPAID,
  1: paymentStatusLabel.FAILED,
  2: paymentStatusLabel.PAID,
  3: paymentStatusLabel.REFUNDED,
};

export const bookingStatus = {
  0: 'Unconfirmed',
  1: 'Confirmed',
  2: 'Cancelled',
  3: 'Rejected',
  4: 'Completed',
};

export const indexableContent = ['Activity', 'ApplicationUser', 'TourProvider', 'ActivityInquiry', 'PrivateActivityInquiry', 'ActivityTag'];


export const suggestionType = {
  EXPERIENCE: 'Activity',
  LOCATION: 'Location',
  PROVIDER: 'TourProvider',
  TAG: 'ActivityTag',
};

export const suggestionIcon = {
  [suggestionType.LOCATION]: <FiMapPin />,
  [suggestionType.PROVIDER]: <FiBriefcase />,
  [suggestionType.TAG]: <FiTag />,
};

export default {
  languages,
  placesTypes,
  indexableContent,
  apiUrl,
  supportedLangs,
  countryId,
  paymentStatusLabel,
  paymentStatus,
  bookingStatus,
  placesTypes,
  suggestionType,
  suggestionIcon,
};
