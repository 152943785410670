import userReducer from './features/auth/userSlice';
import activitiesReducer from './features/dashboard/activitiesSlice';
import exploreReducer from './features/explore/exploreSlice';
import searchReducer from './features/search/searchSlice';
import upcomingReducer from './features/upcoming/upcomingSlice';
import bookingsReducer from './features/bookings/bookingsSlice';
import tourProvidersReducer from './features/superadmin/tourProvidersSlice';
import notificationsReducer from './features/notifications/notificationsSlice';
import blogReducer from './features/blog/blogSlice';
import travelcReducer from './features/travelc/travelcSlice';

export default {
  user: userReducer,
  activities: activitiesReducer,
  explore: exploreReducer,
  search: searchReducer,
  upcoming: upcomingReducer,
  bookings: bookingsReducer,
  tourProviders: tourProvidersReducer,
  notifications: notificationsReducer,
  blog: blogReducer,
  travelc: travelcReducer,
};
